(function() {
    'use strict';

    angular.module("aerosApp").controller("ProjectDashboardCtrl", ProjectDashboardController);

    ProjectDashboardController.$inject = ["$rootScope", "$scope", "aerosApi", "Notification", "$state", "$uibModal",
        "newProjectModal", "mergeProjectsModal", "$translate", "ProjectSort", "ProjectInfoModal", "routesConstant",
        "PageService", "instructions"];

    function ProjectDashboardController($rootScope, $scope, aerosApi, Notification, $state, $uibModal, newProjectModal,
                                        mergeProjectsModal, $translate, ProjectSort, ProjectInfoModal, routesConstant,
                                        PageService, instructions) {
        var CUSTOMER = $scope.CUSTOMER = routesConstant.CUSTOMER;
        var myTimeoutEvent = 0;

        $scope.$watch("commonReady", function (newVal) {
            if (!newVal) return;
            // TODO: AEROS-1496
            if ($scope.allow['viewOnlyProfile']) {
                $state.go( CUSTOMER.ORGANIZATION_PROFILE.stateName, {id: $scope.organization.id});
            }
            $scope.projectFilter = "";
            $scope.projects = [];
            $scope.statusFilter = "active"; // default to 'Active'
            $scope.statusFilterValues = ['active','hidden','activeAndHidden'];
            $scope.statusFilterNames = ['Active','Hidden','Active and Hidden'];

            $scope.sortCol = ProjectSort.sortCol;
            $scope.reverse = ProjectSort.reverse;

            PageService.setDefaultTitle();

            loadProjects();

            $scope.clearBackgroundTaskFlag = function() {
                $uibModal.open({
                    size: "md",
                    templateUrl: 'confirmClearMergeModal.html',
                    controller: ["$scope", "project", function($scope, project){
                        $scope.project = project;
                    }],
                    resolve: {
                        project: function () {
                            return project;
                        }
                    }
                }).result
                    .then(function () {
                        return aerosApi.clearProjectMergeFlag(seletedProjectId, $scope.organization.id);
                    })
                    .then(function() {
                        return loadProjects();
                    });
            }

            function refreshData() {
                window.clearTimeout(myTimeoutEvent);
                myTimeoutEvent = 0;
                loadProjects();
            }

            function loadProjects() {
                // If user doesnt have ability to update project statuses do regular loadProjects() that just returns 'Active' projects.
                if (!$scope.allow['updateProjectStatus']) {
                    aerosApi.loadProjects($scope.organization.id).then(function(resp) {
                        var data = resp.data;
                        $scope.projects = data.projects;
                        return data.projects;
                    });
                }
                // If user is allowed to update proj status, then we want to load both Active and Archived projects for them.
                else {
                    if($scope.statusFilter == "hidden") {
                        aerosApi.loadHiddenProjects($scope.organization.id).then(function(resp) {
                            var data = resp.data;
                            $scope.projects = data.projects;
                            return data.projects;
                        });
                    }
                    else if($scope.statusFilter == "activeAndHidden") {
                        aerosApi.loadActiveAndHiddenProjects($scope.organization.id).then(function(resp) {
                            var data = resp.data;
                            $scope.projects = data.projects;
                            return data.projects;
                        });
                    }
                    else {  // defaults to 'active'
                        if (!$scope.allow['viewOnlyProfile']) {
                            aerosApi.loadProjects($scope.organization.id).then(function(resp) {
                                var data = resp.data;
                                $scope.projects = data.projects;
                                return data.projects;
                            });
                        }
                    }
                }
            }

            $scope.addNewProject = function() {
                if (!$scope.allow["createProjects"]) return;
                newProjectModal.open("Create new project", '', '').then(function(newProject) {//Name, newProjectComment) {
                    // AEROS-1504 hide demo features (import project)
                    // "44" from ng-init in commented-out popup markup
                    if (!newProject.type) {
                        newProject.type = "44";
                    }
                    aerosApi.createProject(newProject, $scope.organization.id).then(function(response) { //Name, newProjectComment).then(function() {
                        if(response.data.code === 500){
                            Notification.error("Project with name '" + newProject.name + "' already exists.");
                        }else{
                            Notification.success("Project '" + newProject.name + "' added successfully");
                            loadProjects();
                        }

                    }, function(response, status){
                        if(response.data.code === 500){
                            Notification.error("Project with name '" + newProject.name + "' already exists.");
                        }else {
                            Notification.error("Error creating project.");
                        }
                    });
                });
            };

            $scope.showProject = function(project) {
                $state.go(CUSTOMER.PROJECTS_PROJECT_FIBERGROUPS.stateName, { id: project.id });
            };

            $scope.deleteProject = function(project) {
                if (!$scope.allow['deleteProjects']) return $scope.reject();
                var seletedProjectId =  project.id;
                $uibModal.open({
                    size: "md",
                    templateUrl: 'confirmDeleteModal.html',
                    controller: ["$scope", "project", function($scope, project){
                        $scope.project = project;
                    }],
                    resolve: {
                        project: function () {
                            return project;
                        }
                    }
                }).result
                    .then(function () {
                        if (!$scope.allow['deleteProjects']) return $scope.reject();
                        return aerosApi.deleteProject(seletedProjectId, $scope.organization.id)
                            .then(results => {
                                Notification.success(`Project '${project.name}' deleted successfully`);
                                return results;
                            });
                    })
                    .then(function() {
                        return loadProjects();
                    });
            };

            $scope.archiveProject = function(project) {
                if (!$scope.allow['updateProjectStatus']) return $scope.reject();
                var seletedProjectId =  project.id;
                $uibModal.open({
                    size: "md",
                    templateUrl: 'confirmArchiveModal.html',
                    controller: ["$scope", "project", function($scope, project){
                        $scope.project = project;
                    }],
                    resolve: {
                        project: function () {
                            return project;
                        }
                    }
                }).result
                    .then(function () {
                        if (!$scope.allow['updateProjectStatus']) return $scope.reject();
                        return aerosApi.updateProjectStatus(seletedProjectId, 'Archived', $scope.organization.id);
                    })
                    .then(function() {
                        return loadProjects();
                    });
            };

            $scope.unarchiveProject = function(project) {
                if (!$scope.allow['updateProjectStatus']) return $scope.reject();
                var seletedProjectId =  project.id;
                $uibModal.open({
                    size: "md",
                    templateUrl: 'confirmUnarchiveModal.html',
                    controller: ["$scope", "project", function($scope, project){
                        $scope.project = project;
                    }],
                    resolve: {
                        project: function () {
                            return project;
                        }
                    }
                }).result
                    .then(function () {
                        if (!$scope.allow['updateProjectStatus']) return $scope.reject();
                        return aerosApi.updateProjectStatus(seletedProjectId, 'Active', $scope.organization.id);
                    })
                    .then(function() {
                        return loadProjects();
                    });
            };

            $scope.hideProject = function(project) {
                if (!$scope.allow['updateProjectStatus']) return $scope.reject();
                var seletedProjectId =  project.id;
                $uibModal.open({
                    size: "md",
                    templateUrl: 'confirmHideModal.html',
                    controller: ["$scope", "project", function($scope, project){
                        $scope.project = project;
                    }],
                    resolve: {
                        project: function () {
                            return project;
                        }
                    }
                }).result
                    .then(function () {
                        if (!$scope.allow['updateProjectStatus']) return $scope.reject();
                        return aerosApi.updateProjectStatus(seletedProjectId, 'Hidden', $scope.organization.id);
                    })
                    .then(function() {
                        return loadProjects();
                    });
            };

            $scope.reShowProject = function(project) {
                if (!$scope.allow['updateProjectStatus']) return $scope.reject();
                var seletedProjectId =  project.id;
                $uibModal.open({
                    size: "md",
                    templateUrl: 'confirmShowModal.html',
                    controller: ["$scope", "project", function($scope, project){
                        $scope.project = project;
                    }],
                    resolve: {
                        project: function () {
                            return project;
                        }
                    }
                }).result
                    .then(function () {
                        if (!$scope.allow['updateProjectStatus']) return $scope.reject();
                        return aerosApi.updateProjectStatus(seletedProjectId, 'Active', $scope.organization.id);
                    })
                    .then(function() {
                        return loadProjects();
                    });
            };

            $scope.showTestResults = function(project) {
                $state.go(CUSTOMER.PROJECTS_PROJECT_RESULTS.stateName, { id: project.id });
            };


            $scope.showDetailedResult = function(project, resultType) {
                $state.go(CUSTOMER.PROJECTS_PROJECT_RESULTS.stateName, {
                    id: project.id,
                    testType: "summary",
                    resultType: resultType
                });
            };

            $scope.editProject = function(project) {
                if (!$scope.allow['createProjects']) return;
                newProjectModal.open("Edit project", project.name, project.comment, "Save","","Update Project").then(function(newProject) {
                    aerosApi.editProject(project.id, newProject, $scope.organization.id).then(function() {
                        loadProjects();
                    });
                });
            };

            $scope.projectIsMerging = function(project) {
                if( project.beingMerged == true && myTimeoutEvent == 0 ) {
                    myTimeoutEvent = 1;
                    myTimeoutEvent = window.setTimeout(refreshData,15000);
                }
                return project.beingMerged;
            };

            $scope.copyProject = function(project) {
                if (!$scope.allow['createProjects']) return;
                var nameLength = 40;
                var tempName = "Copy of " + project.name;
                var newProjectName = tempName.length > nameLength ? tempName.substring(0, nameLength) : tempName;
                newProjectModal.open("Copy project", newProjectName, project.comment).then(function(newProject) {
                    aerosApi.copyProject(project.id, newProject.name, $scope.organization.id, newProject.comment).then(function(result) {
                        if(result.data.code === 500){
                            Notification.error(result.data.message);
                        } else{
                            loadProjects();
                        }
                    });
                });
            };

            $scope.mergeProjects = function () {
                if (!$scope.allow['createProjects']) return;
                mergeProjectsModal.open($scope.projects).then(function(formObj) {
                    var projectIds = formObj.projectIds;
                    var newProjectName = formObj.newProjectName;
                    var mergeInBackground = formObj.mergeFgInbackground;
                    aerosApi.mergeProjects(projectIds, newProjectName, mergeInBackground, $scope.organization.id).then(function() {
                        loadProjects();
                    }, function(results) {
                        Notification.error(results.data.message);
                    });
                });
            };

            $scope.reveal = $rootScope.userSettings.showProgress;
            $scope.toggleReveal = function () {
                $scope.reveal = !$scope.reveal;
                $rootScope.userSettings.showProgress = $scope.reveal;
            };

            $scope.updateStatusFilter = function (statusFilter) {
                $scope.statusFilter = statusFilter;
                loadProjects();
            };

            $scope.order = function(sortCol) {
                ProjectSort.reverse = (ProjectSort.sortCol === sortCol) ? !ProjectSort.reverse : false;
                ProjectSort.sortCol = sortCol;

                $scope.sortCol = ProjectSort.sortCol;
                $scope.reverse = ProjectSort.reverse;
            };

            $scope.showProjectInfo = ProjectInfoModal.open;

            /*	AEROS-1504 hide demo features
                    // upload competitor file
                    $scope.associateFile = function (project) {
                        var configObj = {
                                project: project,
                                linkLevels: ['project']
                        };
                        addAttachmentModal.open(configObj).then(function(attachmentObj) {

                            attachmentObj.orgId = $scope.organization.id;
                            attachmentObj.projectId = project.id;

                            aerosApi.saveAttachment(attachmentObj).then(function (rtn) {
                                loadProjects();
                                Notification.success("Successfully attached the file");
                                console.log('rtn', rtn);
                            }, function (err) {
                                Notification.error("Error attaching the file");
                                console.log('err', err);
                            });
                        });
                    };
            */


            function findInstructionByAction(action) {
                return instructions[routesConstant.CUSTOMER.PROJECTS.stateName]
                    .actions.find(function (element) {
                        return element.action && element.action.toLowerCase() === action.toLowerCase();
                    });
            }

            (function setActions() {

                $scope.headerActions = [{
                    label: function() {
                        return findInstructionByAction("Project Details").instructions;
                    },
                    icon: function() {
                        return "fa-" + findInstructionByAction("Project Details").icon;
                    },
                    if: function() {
                        return $scope.allow.viewProgress;
                    },
                    action: function() {
                        return $scope.toggleReveal();
                    }
                }, {
                    label: function() {
                        return findInstructionByAction("Merge Projects").instructions;
                    },
                    icon: function() {
                        return "fa-" + findInstructionByAction("Merge Projects").icon;
                    },
                    if: function() {
                          return ($scope.$parent.accountRoles == "Professional") && $scope.allow.mergeProjects && $scope.projects.length > 1;
                    },
                    action: function() {
                        return $scope.mergeProjects();
                    }
                }, {
                    label: function() {
                        return findInstructionByAction("Create Project").instructions;
                    },
                    icon: function() {
                        return "fa-" + findInstructionByAction("Create Project").icon;
                    },
                    if: function() {
                          return ($scope.$parent.accountRoles == "Professional") && ($scope.allow.createProjects === true);
                    },
                    action: function() {
                        return $scope.addNewProject();
                    },
                    placement: function() {
                        return 'left';
                    }
                }];

                $scope.actions = [{
                    label: function (project) {
                        return findInstructionByAction('Project Merging').instructions;
                    },
                    icon: function (project) {
                        return 'hideBackground fa-spin fa-spinner';
                    },
                    if: function (project) {
                        return $scope.projectIsMerging(project);
                    },
                    disabled: function (project) {
                        false;
                    },
                    action: function (project) {
                        $scope.clearBackgroundTaskFlag(project);
                    }
                }, {
                    label: function (project) {
                        return findInstructionByAction('Edit Project').instructions;
                    },
                    icon: function (project) {
                        return 'fa-pencil';
                    },
                    if: function (project) {
                        return $scope.allow.editProjects && (project.originator === 'aeros' || $scope.allow.editGemini);
                    },
                    disabled: function (project) {
                        return project.status === 'Archived' || project.status === 'Hidden';
                    },
                    action: function (project) {
                        $scope.editProject(project);
                    }
                }, {
                    label: function (project) {
                        return findInstructionByAction('Info').instructions;
                    },
                    icon: function (project) {
                        return 'fa-info';
                    },
                    if: function (project) {
                        return true;
                    },
                    disabled: function (project) {
                        return false;
                    },
                    action: function (project) {
                        $scope.showProjectInfo(project);
                    }
                }, {
                    label: function (project) {
                        return findInstructionByAction("View Results").instructions;
                    },
                    icon: function (project) {
                        return 'fa-' + findInstructionByAction("View Results").icon;
                    },
                    if: function (project) {
                        return true;
                    },
                    disabled: function (project) {
                        return false;
                    },
                    action: function (project) {
                        $scope.showDetailedResult(project, 'All');
                    }
                }, {
                    label: function (project) {
                        return project.status === 'Hidden'
                            ? findInstructionByAction('Unhide Project').instructions
                            : findInstructionByAction('Hide Project').instructions;
                    },
                    icon: function (project) {
                        return (project.status === 'Hidden') ? 'fa-undo btn-reload' : 'fa-ban btn-hide';
                    },
                    if: function (project) {
                        return $scope.allow.updateProjectStatus;
                    },
                    disabled: function (project) {
                        return false;
                    },
                    action: function (project) {
                        (project.status === 'Hidden') ? $scope.reShowProject(project) : $scope.hideProject(project);
                    }
                }, {
                    label: function (project) {
                        return findInstructionByAction('Copy Project').instructions;
                    },
                    icon: function (project) {
                        return 'fa-' + findInstructionByAction('Copy Project').icon;
                    },
                    if: function (project) {
                        return ($scope.$parent.accountRoles == "Professional") && ($scope.allow.copyProjects === true);
                    },
                    disabled: function (project) {
                        return false;
                    },
                    action: function (project) {
                        $scope.copyProject(project);
                    },
                    placement: function(project) {
                        return 'left';
                    }
                }, {
                    label: function (project) {
                        return (project.status === 'Archived' ? 'Reload' : 'Archive') + ' Project';
                    },
                    icon: function (project) {
                        return (project.status === 'Archived') ? 'fa-undo btn-reload' : 'fa-archive btn-archive';
                    },
                    if: function (project) {
                        return $scope.allow.archiveProject;
                    },
                    disabled: function (project) {
                        return false;
                    },
                    action: function (project) {
                        return (project.status === 'Archived')
                            ? $scope.unarchiveProject(project)
                            : $scope.archiveProject(project);
                    }
                }, {
                    label: function (project) {
                        return findInstructionByAction('Delete Project').instructions;
                    },
                    icon: function (project) {
                        return "fa-trash";
                    },
                    if: function (project) {
                        return ($scope.$parent.accountRoles == "Professional") && ($scope.allow.deleteProjects === true);
                    },
                    disabled: function (project) {
                        return false;
                    },
                    action: function (project) {
                        $scope.deleteProject(project);
                    },
                    placement: function(project) {
                        return 'left';
                    }
                }];
            })();
        });
    }


})();
